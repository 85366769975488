import {
  IconBalances,
  IconHelp,
  IconHome,
  IconPaymentMethods,
  IconPayments,
  IconRateAlerts,
  IconRecipients,
  IconQuickTransfer,
  IconMarketOrders,
  IconRegularPayments,
} from '@galileo/assets/icons/navMenu'
import {
  useAppStore,
  useAuthStore,
  useI18nStore,
  usePromotionStore,
  useThemeStore,
} from '@galileo/stores'
import { Ref } from '@vue/composition-api'

import {
  IconFill,
  IconGift,
  IconWallet,
  IconTime,
  IconTimeFilled,
  IconGroup,
  IconGroupFilled,
  IconSendAlt,
  IconSendAltFilled,
  //@ts-ignore
} from '@oen.web.vue2/icons'

import { PROMOTIONS_TYPE } from '@galileo/constants/promotions.const'

export const getMenuItemsBasedOnRole = (
  loadingHelpDesk: Ref<boolean>,
  isBottomMenuItems: boolean = false
) => {
  if (useAuthStore().getIsBalancesEnabled) {
    return createBalanceUserMenuItems(loadingHelpDesk, isBottomMenuItems)
  } else if (isBottomMenuItems) {
    return createConsumerBottomMenuItems(loadingHelpDesk)
  } else {
    return createConsumerSideMenuItems(loadingHelpDesk)
  }
}

const createBalanceUserMenuItems = (loadingRef: Ref<boolean>, isBottomMenuItems: boolean) => {
  const { $t } = useI18nStore()
  const isBranded = useThemeStore().isBranded
  const authStore = useAuthStore()

  const user = authStore.user

  const menuItems = []
  menuItems.push({
    id: 'home-link',
    text: $t('ComponentXeSidebar.Balances.Home').value,
    route: { name: 'Activity' },
    icon: IconHome,
  })
  menuItems.push({
    id: 'balances-link',
    text: $t('ComponentXeSidebar.Balances.BalancesOverview').value,
    route: { path: '/balances' },
    icon: IconBalances,
  })
  menuItems.push({
    id: 'recipients-link',
    text: $t('ComponentRiaSidebar.ButtonRecipients').value,
    route: { name: 'Recipients' },
    icon: IconRecipients,
  })
  menuItems.push({
    id: 'payments-link',
    text: $t('ComponentXeSidebar.Balances.Payments').value,
    route: { name: 'Payments' },
    icon: IconPayments,
  })
  if (user?.customer.features.rpaEnabled) {
    menuItems.push({
      id: 'regular-payments',
      text: $t('ComponentXeSidebar.RegularPayments').value,
      route: { name: 'RegularPayments' },
      icon: IconRegularPayments,
    })
  }
  if (user?.customer.features.marketOrder) {
    menuItems.push({
      id: 'market-order',
      text: $t('ComponentXeSidebar.ButtonMarketOrder').value,
      route: { name: 'MarketOrder' },
      icon: IconMarketOrders,
    })
  }
  menuItems.push({
    id: 'payment-methods-link',
    text: $t('ComponentXeSidebar.Balances.PaymentMethods').value,
    route: { name: 'AccountPaymentMethods' },
    icon: IconPaymentMethods,
  })
  if (!isBranded) {
    menuItems.push({
      id: 'rate-alerts-link',
      text: $t('ComponentXeSidebar.Balances.RateAlerts').value,
      href: 'https://xera.xe.com/',
      icon: IconRateAlerts,
      target: '_new',
    })
  }
  menuItems.push({
    id: 'open-help-link',
    text: $t('ComponentXeSidebar.Balances.Help').value,
    click: () => {
      useAppStore().openHelpDesk({ loadingRef: loadingRef })
    },
    icon: IconHelp,
  })

  if (isBottomMenuItems) {
    const bottomMenuItems = menuItems.map((item) => ({
      ...item,
      name: item.text,
      activeIcon: item.icon,
    }))
    bottomMenuItems.splice(2, 0, {
      id: 'send-money',
      text: '',
      name: '',
      icon: IconQuickTransfer,
      activeIcon: IconQuickTransfer,
      route: { name: 'SendMoneyAmount' },
    })
    return bottomMenuItems
  }
  return menuItems
}

const createConsumerSideMenuItems = (loadingRef: Ref<boolean>) => {
  const { $t } = useI18nStore()
  const isBranded = useThemeStore().isBranded
  const authStore = useAuthStore()
  const promotionStore = usePromotionStore()

  const user = authStore.user
  const isCorporateAccount = authStore.isCorporateAccount
  const isDirectDebitEnabledAccount = authStore.getIsDirectDebitEnabledAccount

  const menuItems = []
  menuItems.push({
    id: 'activity',
    text: $t('ComponentXeSidebar.ButtonActivity').value,
    route: { name: 'Activity' },
  })
  menuItems.push({
    id: 'recipients',
    text: $t('ComponentXeSidebar.ButtonRecipients').value,
    route: { name: 'Recipients' },
  })

  // dispay payment methods to consumers or corporate that country use direct debit
  if (!isCorporateAccount || (isCorporateAccount && isDirectDebitEnabledAccount)) {
    menuItems.push({
      id: 'payment',
      text: $t('ComponentXeSidebar.ButtonPayment').value,
      route: { name: 'AccountPaymentMethods' },
    })
  }

  if (user?.customer.features.marketOrder) {
    menuItems.push({
      id: 'market-order',
      text: $t('ComponentXeSidebar.ButtonMarketOrder').value,
      route: { name: 'MarketOrder' },
    })
  }
  if (user?.customer.features.useBalance || user?.customer.features.viewBalance) {
    menuItems.push({
      id: 'balances',
      text: $t('ComponentXeSidebar.ButtonBalancesAndPayments').value,
      route: { name: 'BalancesAndPayments' },
    })
  } else {
    menuItems.push({
      id: 'payments',
      text: $t('ComponentXeSidebar.ButtonPayments').value,
      route: { name: 'Payments' },
    })
  }
  if (user?.customer.features.rpaEnabled) {
    menuItems.push({
      id: 'regular-payments',
      text: $t('ComponentXeSidebar.RegularPayments').value,
      route: { name: 'RegularPayments' },
    })
  }

  if (!isBranded) {
    menuItems.push({
      id: 'rate-alerts',
      text: $t('ComponentXeSidebar.RateAlerts').value,
      href: 'https://xera.xe.com/',
      icon: IconFill,
      target: '_new',
    })
  }

  menuItems.push({
    id: 'open-help-desk-link',
    text: $t('ComponentXeSidebar.ButtonHelp').value,
    click: () => useAppStore().openHelpDesk({ loadingRef: loadingRef }),
    icon: IconFill,
  })
  const referAFriendPromoData = promotionStore.getPromotionDataByPromoName(
    PROMOTIONS_TYPE.REFER_FRIEND
  )

  if (promotionStore.getCanAccessPromotion() && referAFriendPromoData) {
    menuItems.push({
      id: 'refer-a-friend',
      text: $t('ComponentXeSideBar.ButtonReferAFriend', {
        value: referAFriendPromoData.data.giftCardValue,
      }).value,
      route: { name: 'InviteFriend' },
      icon: IconGift,
    })
  }
  return menuItems
}

const createConsumerBottomMenuItems = (loadingRef: Ref<boolean>) => {
  const { $t } = useI18nStore()
  const isBranded = useThemeStore().isBranded
  const promotionStore = usePromotionStore()
  const authStore = useAuthStore()

  let menuItems: any[] = [
    {
      name: $t('ComponentXeBottombar.ButtonActivity').value,
      route: { name: 'Activity' },
      icon: IconTime,
      activeIcon: IconTimeFilled,
      permission: true,
    },
    {
      name: $t('ComponentXeBottombar.ButtonRecipients').value,
      route: { name: 'Recipients' },
      icon: IconGroup,
      activeIcon: IconGroupFilled,
      permission: true,
    },
    {
      name: $t('ComponentXeBottombar.ButtonSend').value,
      route: { name: 'SendMoneyAmount' },
      icon: IconSendAlt,
      activeIcon: IconSendAltFilled,
      permission: true,
    },
    {
      name: $t('ComponentXeBottombar.ButtonPayment').value,
      route: { name: 'AccountPaymentMethods' },
      icon: IconWallet,
      activeIcon: IconWallet,
      permission: true,
    },
  ]

  const referAFriendPromoData = promotionStore.getPromotionDataByPromoName(
    PROMOTIONS_TYPE.REFER_FRIEND
  )

  if (promotionStore.getCanAccessPromotion() && referAFriendPromoData) {
    menuItems.push({
      id: 'refer-a-friend',
      name: $t('ComponentXeSideBar.ButtonReferAFriend', {
        value: referAFriendPromoData.data.giftCardValue,
      }).value,
      route: { name: 'InviteFriend' },
      icon: IconGift,
      activeIcon: IconGift,
      permission: promotionStore.getCanAccessPromotion(),
    })
  }

  menuItems.push(
    ...[
      {
        ...(authStore.user?.customer.features.marketOrder && {
          name: $t('ComponentXeBottombar.ButtonMarketOrder').value,
          route: { name: 'MarketOrder' },
          icon: null,
          activeIcon: null,
          permission: authStore.user?.customer.features.marketOrder,
        }),
      },
      {
        ...((authStore.user?.customer.features.useBalance ||
          authStore.user?.customer.features.viewBalance) && {
          name: $t('ComponentXeBottombar.ButtonBalancesAndPayments').value,
          route: { name: 'BalancesAndPayments' },
          permission:
            authStore.user?.customer.features.useBalance ||
            authStore.user?.customer.features.viewBalance,
        }),
      },
      {
        ...(!(
          authStore.user?.customer.features.useBalance ||
          authStore.user?.customer.features.viewBalance
        ) && {
          name: $t('ComponentXeBottombar.ButtonPayments').value,
          route: { name: 'Payments' },
          permission: !(
            authStore.user?.customer.features.useBalance ||
            authStore.user?.customer.features.viewBalance
          ),
        }),
      },
      {
        ...(authStore.user?.customer.features.rpaEnabled && {
          name: $t('ComponentXeBottombar.ButtonRegularPayments').value,
          route: { name: 'RegularPayments' },
          permission: authStore.user?.customer.features.rpaEnabled,
        }),
      },
    ]
  )

  if (!isBranded) {
    menuItems.push({
      name: $t('ComponentXeBottombar.ButtonRateAlerts').value,
      href: `https://xera.xe.com/`,
      target: '_blank',
      imgName: 'fill.png',
      permission: true,
    })
  }
  menuItems.push(
    ...[
      {
        name: $t('ComponentXeBottombar.ButtonHelp').value,
        click: () => useAppStore().openHelpDesk({ loadingRef: loadingRef }),
        target: '_blank',
        imgName: 'fill.png',
        permission: true,
      },
    ]
  )

  return menuItems
}
