<template>
  <div :class="{ 'balance-enabled-menu': isBalancesEnabled }">
    <AppSpinnerBig :loading="loadingHelpDesk"></AppSpinnerBig>
    <AppNavSidebar v-if="mq.current !== 'xs'" class="xe-sidebar" :items="items">
      <template #header>
        <div class="xe-sidebar-header">
          <AppLink
            href="/activity"
            analytics-name="sidebar-logo-link"
            aria-label="Xe Logo"
            :class="{ branded: isBranded }"
          >
            <XeLogo class="xe-sidebar-header-image" />
          </AppLink>
        </div>
        <div class="xe-sidebar-footer flex flex-col items-center">
          <AppButton analytics-name="sidebar-send-money" theme="primary" @click="sendMoney">
            {{ $t('ComponentXeSidebar.ButtonSendMoney').value }}
          </AppButton>
        </div>
      </template>

      <template #middle>
        <ul v-if="shouldShowFxWebSwitch">
          <li class="nav-sidebar-links-link">
            <AppLink theme="secondary" class="fxweb-switch-text" @click="switchToFxWebModalHandler">
              {{ $t('ComponentXeSidebar.SwitchButton').value }}
              <template #rightIcon>
                <AppInputToggle class="fxweb-switch-toggle" :value="toggleSwitchToFxWebModal" />
              </template>
            </AppLink>
          </li>
        </ul>
      </template>
    </AppNavSidebar>
  </div>
</template>

<script>
import { ref, computed, reactive } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import { useRouter } from '@galileo/composables/useRouter'
import XeLogo from '@galileo/components/XeLogo/XeLogo'
import { IconGift, IconFill } from '@oen.web.vue2/icons'
import { useTheme } from '@galileo/composables/useTheme'
import SwitchToLegacySiteModal from '@galileo/components/SwitchToLegacySiteModal/SwitchToLegacySiteModal'

import {
  useMediaQuery,
  AppSpinnerBig,
  AppNavSidebar,
  AppLink,
  AppButton,
  AppInputToggle,
} from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useThemeStore,
  useSendMoneyStore,
  useAppStore,
  useAuthStore,
  useProfileStore,
  useAnalyticsStore,
  usePromotionStore,
} from '@galileo/stores'

import { PROMOTIONS_TYPE } from '@galileo/constants/promotions.const'

import { getMenuItemsBasedOnRole } from '@galileo/composables/useCreateSideMenuItems'

export default {
  name: 'XeSidebar',
  components: {
    XeLogo,
    AppSpinnerBig,
    AppButton,
    AppLink,
    AppNavSidebar,
    AppInputToggle,
    SwitchToLegacySiteModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const router = useRouter()
    const appStore = useAppStore()
    const profileStore = useProfileStore()
    const sendMoneyStore = useSendMoneyStore()
    const promotionStore = usePromotionStore()
    const analyticsStore = useAnalyticsStore()
    const { showSwitchToFxWebModal } = storeToRefs(profileStore)
    const { getIsDirectDebitEnabledAccount, lastLogin } = authStore
    const { brandedXeLogoSrc } = useTheme()
    const isBranded = computed(() => useThemeStore().isBranded)
    const sendMoney = () => {
      sendMoneyStore.restoreDefaultForm()
      router.push('/send-money')
    }
    const user = authStore.user
    const isFxWebCorpMigrated = user.fxWebCorpMigrated
    const { isCorporateAccount } = storeToRefs(authStore)
    const isBalancesEnabled = useAuthStore().getIsBalancesEnabled

    const shouldShowFxWebSwitch =
      isCorporateAccount.value && isFxWebCorpMigrated && !isBalancesEnabled
    const loadingHelpDesk = ref(false)

    const menuItems = computed(() => getMenuItemsBasedOnRole(loadingHelpDesk))

    const onLogoClick = () => {
      //[TODO] what should happen?
    }

    const openHelpDesk = () => {
      appStore.openHelpDesk({ loadingRef: loadingHelpDesk })
    }

    const toggleSwitchToFxWebModal = computed(() => {
      return showSwitchToFxWebModal.value
    })

    const switchToFxWebModalHandler = () => {
      showSwitchToFxWebModal.value = true
    }

    return {
      mq: useMediaQuery(),
      sendMoney,
      items: menuItems,
      $t,
      onLogoClick,
      openHelpDesk,
      loadingHelpDesk,
      isBranded,
      brandedXeLogoSrc: brandedXeLogoSrc(),
      shouldShowFxWebSwitch,
      switchToFxWebModalHandler,
      toggleSwitchToFxWebModal,
      isBalancesEnabled,
    }
  },
}
</script>
<style scoped>
.balance-enabled-menu {
  .nav-sidebar {
    @apply border-transparent;
  }
  ::v-deep .nav-sidebar svg path {
    fill: theme('colors.grayblue.200');
  }
  ::v-deep .xe-sidebar .nav-sidebar-links-link {
    a {
      @apply flex flex-row-reverse items-center justify-end font-semibold px-5 rounded-r-xl;
    }
    .link-text {
      @apply flex flex-row-reverse items-center justify-end font-semibold;
    }
    button {
      @apply px-5 rounded-r-xl;
    }
    svg {
      @apply ml-0 mr-4;
    }
  }

  ::v-deep .nav-sidebar .nav-sidebar-links-link button:hover {
    @apply text-grayblue-200;
  }
  ::v-deep .nav-sidebar .nav-sidebar-links-link a {
    &:hover {
      @apply text-grayblue-200;
    }

    &:before {
      @apply bg-transparent;
    }
    &.router-link-active {
      @apply text-grayblue-400;
      svg path {
        fill: theme('colors.grayblue.400');
      }
    }
  }
  .xe-sidebar-footer {
    @apply px-5;
    button:hover {
      @apply text-white;
    }
  }
}

::v-deep .xe-sidebar {
  .nav-sidebar-links-link {
    a,
    .link-text {
      font-weight: var(--theme-sidebar-font-weight, theme('fontWeight.medium'));
    }
  }

  *[analytics-name='navsidebar-refer-a-friend'] {
    svg {
      @apply relative;
      padding-bottom: 2px;
      bottom: 2px;
    }
  }
}

.xe-sidebar-footer {
  @apply flex justify-center;
  @apply p-8 pt-0;
}

::v-deep .nav-sidebar-links-link span {
  font-weight: 500;
}

.navbar-logo-text {
  margin-left: 2px;
  font-size: 13px;
  font-weight: 600;
}
.fxweb-switch-toggle {
  padding: 0.125rem !important;
  width: 2rem !important;
  height: 1rem;
}
::v-deep .toggle-button .knob .circle {
  width: 0.75rem;
  height: 0.75rem;
}
::v-deep .toggle-button .knob .halo {
  width: 28px;
  height: 28px;
  top: -8px;
  left: -10px;
}

::v-deep .xe-sidebar .xe-sidebar-header-image {
  max-height: var(--theme-sidebar-header-image-max-height, 100%);
}

::v-deep .nav-sidebar .nav-sidebar-links-link button:focus,
::v-deep .nav-sidebar .nav-sidebar-links-link button:focus-visible {
  outline: none;
}

.xe-sidebar-header {
  @apply p-8;
}

::v-deep .nav-sidebar {
  width: 17rem;
  background-color: var(--theme-background, rgba(243, 244, 246, 1));
  border-color: var(--theme-card-border-color, theme('colors.gray.lighter'));

  .nav-sidebar-links-link {
    a,
    button {
      color: var(
        --theme-sidebar-links-color,
        var(--theme-text-primary, theme('colors.primary.lighter'))
      );
      &:before {
        background-color: var(
          --theme-step-active-color,
          var(--theme-color-primary, rgba(0, 108, 224, 1))
        );
      }
      &.router-link-active {
        color: var(--theme-step-active-color, var(--theme-color-primary, rgba(0, 108, 224, 1)));
      }
    }
  }
}
</style>
