<template>
  <div
    v-if="currentProfile && currentProfile.customer"
    :class="['profile-selector', { 'profile-selector-button--active': isMenuVisible }]"
  >
    <button
      class="profile-selector-button"
      @click.stop="isMenuVisible = !isMenuVisible"
      @click="moreClick()"
    >
      <AppFigure class="profile-selector-figure">
        <AppIcon name=" ">
          <IconUser />
        </AppIcon>
      </AppFigure>

      <div v-if="isCorporateAccount" class="profile-selector-text">
        <AppListItemTitle> {{ currentProfile.customer.accountName }} </AppListItemTitle>
        <AppListItemCaption> {{ getClientFullName() }} </AppListItemCaption>
      </div>
      <div v-else class="profile-selector-text">
        <AppListItemTitle> {{ currentProfile.customer.accountName }} </AppListItemTitle>
        <AppListItemCaption> {{ currentProfile.customer.clientNumber }} </AppListItemCaption>
      </div>

      <AppIcon name=" ">
        <IconChevronDown class="profile-selector-chevron" />
      </AppIcon>
    </button>

    <Transition name="p-connected-overlay">
      <AppCard v-if="isMenuVisible" class="profile-selector-menu">
        <AppList>
          <template>
            <!-- TODO temporary changed -->
            <!--
            <AppListItem
              v-for="detailedProfile in detailedProfiles"
              :key="detailedProfile.id"
              :analytics-name="`profile-item-${detailedProfile.id}`"
              -@itemClick="selectProfile(detailedProfile)"
            >
            -->
            <AppListItem
              v-for="detailedProfile in detailedProfiles"
              :key="detailedProfile.id"
              :analytics-name="`profile-item-${detailedProfile.id}`"
            >
              <template #figure>
                <AppFigure theme="gray">
                  <AppIcon name=" " v-if="isCorporateAccount">
                    <IconPortfolio />
                  </AppIcon>
                  <AppIcon name=" " v-else>
                    <IconUser />
                  </AppIcon>
                </AppFigure>
              </template>
              <template #left>
                <div v-if="isCorporateAccount">
                  <AppListItemTitle> {{ currentProfile.customer.accountName }} </AppListItemTitle>
                  <AppListItemCaption> {{ getClientFullName() }} </AppListItemCaption>
                  <AppListItemCaption>
                    {{
                      `${detailedProfile.clientNumber} | ${
                        detailedProfile.region
                      } ${titleAccountType()}`
                    }}
                  </AppListItemCaption>
                </div>
                <div v-else>
                  <AppListItemTitle> {{ detailedProfile.name }} </AppListItemTitle>
                  <AppListItemCaption> {{ detailedProfile.clientNumber }} </AppListItemCaption>
                  <AppListItemCaption>
                    {{ `${detailedProfile.region} ${titleAccountType()}` }}
                  </AppListItemCaption>
                </div>
              </template>
            </AppListItem>
          </template>
        </AppList>

        <AppList class="profile-selector-menu-content" border-top>
          <XeListItem
            :show-action-icon="false"
            @select="openSettings"
            :text="$t('ComponentProfileSelectionDropdown.SettingsText').value"
          >
            <template #figure-icon>
              <AppIcon name=" ">
                <IconSettings />
              </AppIcon>
            </template>
          </XeListItem>

          <!-- <AppListItem @itemClick="openSettings">
            <template #left>
              <AppListItemTitle class="profile-selector-menu-title">
                <AppIcon name=" " class="profile-selector-menu-icon">
                  <IconSettings />
                </AppIcon>
                {{
                  $t('ComponentProfileSelectionDropdown.SettingsText').value
                }}
              </AppListItemTitle>
            </template>
          </AppListItem> -->

          <XeListItem
            :show-action-icon="false"
            @select="openDocument"
            :text="$t('ComponentProfileSelectionDropdown.ImportantInformationText').value + '   ↗'"
          >
            <template #figure-icon>
              <AppIcon name=" ">
                <IconInformation />
              </AppIcon>
            </template>
          </XeListItem>

          <XeListItem
            :show-action-icon="false"
            @select="onLogoutClick"
            :text="$t('ComponentProfileSelectionDropdown.LogoutText').value"
          >
            <template #figure-icon>
              <AppIcon name=" ">
                <IconLogout />
              </AppIcon>
            </template>
          </XeListItem>
        </AppList>
      </AppCard>
    </Transition>

    <SignOutModal v-if="showLogoutDialog" v-model="showLogoutDialog" />
  </div>
</template>

<script>
import InformationAppModal from '@galileo/components/InformationAppModal/InformationAppModal'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import {
  AppListItem,
  AppList,
  AppCard,
  AppListItemTitle,
  AppListItemCaption,
  AppIcon,
  AppFigure,
  AppButton,
} from '@oen.web.vue2/ui'

import XeListItem from '@galileo/components/XeListItem/XeListItem.vue'
import {
  IconCheckmark,
  IconUser,
  IconPortfolio,
  IconChevronDown,
  IconInformation,
  IconSettings,
  IconLogout,
  IconLaunch,
} from '@oen.web.vue2/icons'

import { ref, onMounted, onBeforeUnmount, computed } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import { useRouter } from '@galileo/composables/useRouter'
import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'
import SignOutModal from '@galileo/components/Views/Account/Profile/ProfileModal/SignOutModal'

import {
  useI18nStore,
  useResourcesStore,
  useAuthStore,
  useDeviceStore,
  useAnalyticsStore,
  useAppStore,
  useOtpStore,
  useEnvStore,
} from '@galileo/stores'

export default {
  name: 'ProfileSelectionDropdown',
  components: {
    AppListItemTitle,
    AppListItem,
    AppList,
    AppCard,
    AppIcon,
    AppFigure,
    IconCheckmark,
    IconUser,
    IconPortfolio,
    IconChevronDown,
    IconInformation,
    IconSettings,
    IconLogout,
    IconLaunch,
    AppListItemCaption,
    AppButton,
    InformationAppModal,
    XeListItem,
    SignOutModal,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const resourcesStore = useResourcesStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const deviceStore = useDeviceStore()
    const isMenuVisible = ref(false)
    const showLogoutDialog = ref(false)
    const currentProfile = computed(() => {
      return authStore.userProfile
    })
    const rememberDevice = ref(deviceStore.rememberDevice)

    const detailedProfiles = computed(() => {
      const profiles = authStore.getUserProfiles()

      if (profiles && currentProfile.value) {
        return Object.values(profiles).filter(
          (profile) => profile.id === currentProfile.value?.customer?.id
        )
      }
      return null
    })

    const { isCorporateAccount } = storeToRefs(authStore)

    const moreClick = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.MORE_MENU,
        traits: {},
      })
    }

    const selectProfile = async (profile) => {
      authStore.selectedUser = profile.customer

      await authStore.selectProfile(profile.id, profile.accountType, router)
      if (!isCorporateAccount.value) {
        router.go(0)
      }
    }

    const titleAccountType = () => {
      if (isCorporateAccount.value) {
        return $t('ComponentProfileSelectionDropdown.BusinessText').value
      } else {
        return $t('ComponentProfileSelectionDropdown.PersonalText').value
      }
    }

    onBeforeUnmount(() => {
      document.removeEventListener('click', () => {
        isMenuVisible.value = false
      })
    })

    onMounted(() => {
      document.addEventListener('click', () => {
        isMenuVisible.value = false
      })
    })

    const openDocument = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.IMPORTANT_INFORMATION,
        article: HelpDesk[HelpDeskProperties.IMPORTANT_INFORMATION],
      })
    }
    const openSettings = () => {
      router.push('/account')
    }

    const logoutLoading = ref(false)

    const getClientFullName = () => {
      return `${currentProfile.value.customer.firstName} ${currentProfile.value.customer.lastName}`
    }

    const onLogoutClick = () => {
      showLogoutDialog.value = true
    }

    return {
      detailedProfiles,
      selectProfile,
      currentProfile,
      titleAccountType,
      openDocument,
      openSettings,
      isMenuVisible,
      showLogoutDialog,
      logoutLoading,
      $t,
      moreClick,
      isCorporateAccount,
      getClientFullName,
      onLogoutClick,
      rememberDevice,
    }
  },
}
</script>
<style scoped>
.profile-selector {
  @apply p-0 rounded-md max-w-max-content;

  &:hover {
    background: transparent !important;
  }

  .profile-selector-button {
    @apply flex flex-row items-center text-left max-w-max-content;
  }

  .profile-selector-figure {
    @apply border-0;
    @apply bg-white;
  }

  .profile-selector-chevron {
    @apply text-gray;
    transform-origin: 50% 50%;
    transition: transform 0.1s;
    transition-timing-function: linear;
  }

  .profile-selector-text {
    @apply mx-0 hidden;
  }

  .list-item-caption {
    @apply text-xs;
  }

  @screen sm {
    .profile-selector-text {
      display: inherit;
      @apply mx-4 flex-col;
    }
  }
}

.profile-selector-button--active {
  background: transparent;

  @screen md {
    ::v-deep main {
      background: rgba(235, 237, 241, 1);
    }
  }

  .profile-selector-chevron {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
    transition: transform 0.1s;
    transition-timing-function: linear;
  }
}

.profile-selector-menu {
  @apply absolute right-0 top-14 ml-6 rounded-none;
  @apply border-none w-screen;
  box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.15);
  z-index: 100;
  min-width: 344px;
  min-height: 320px;

  .list-item {
    @apply border-none;
  }

  .figure--gray {
    background-color: rgba(250, 251, 253, 1);
    border: 1px solid rgba(243, 244, 246, 1);
  }

  .list-item-caption {
    @apply text-xs;
  }

  ::v-deep .list-item-content {
    @apply py-3 border-solid;
  }

  ::v-deep .card-content {
    @screen sm {
      @apply px-4 py-0;
    }
  }

  ::v-deep .card-content-block {
    @apply p-0 m-0;
  }

  .list-item-title {
    @apply flex justify-start items-center gap-2;
  }

  .icon-launch {
    @apply h-3 w-3;
  }
}

.profile-selector-menu-icon {
  @apply hidden;
}

@screen sm {
  .profile-selector {
    @apply p-2;
    @apply relative;
    top: 1rem;

    &:hover {
      background: rgba(235, 237, 241, 1);
    }
  }

  .profile-selector-menu {
    @apply top-20 mr-0 w-max-content rounded-none;
    border-radius: 8px;
    min-height: 250px;
  }

  .profile-selector-menu-icon {
    @apply flex;
  }
}

.profile-selector-menu-title {
  @apply font-medium my-3 text-tertiary-text;
}
</style>
