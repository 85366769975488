<template>
  <AppNavBottombar :class="isBalancesEnabled ? 'balances-menu' : ''" :items="menuItems" />
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { AppNavBottombar } from '@oen.web.vue2/ui'

import { useAuthStore } from '@galileo/stores'
import { getMenuItemsBasedOnRole } from '@galileo/composables/useCreateSideMenuItems'

export default {
  name: 'XeBottombar',
  components: {
    AppNavBottombar,
  },
  setup() {
    const authStore = useAuthStore()

    const loadingHelpDesk = ref(false)

    const menuItems = computed(() => getMenuItemsBasedOnRole(loadingHelpDesk, true))

    return {
      // items,
      menuItems,
      isBalancesEnabled: authStore.getIsBalancesEnabled,
    }
  },
}
</script>

<style scoped>
.nav-bottombar.balances-menu {
  @apply h-18 border-t border-gray-400;
}

nav.nav-bottombar {
  @apply z-10;
}

.balances-menu {
  ::v-deep ul.nav-bottombar-list .nav-bottombar-list-item a,
  ::v-deep ul.nav-bottombar-list .nav-bottombar-list-item button {
    @apply text-grayblue-200 font-semibold;
    height: unset;
    width: unset;
    svg path {
      fill: theme('colors.grayblue.200');
    }
    &.router-link-active {
      @apply text-grayblue-400;
      svg path {
        fill: theme('colors.grayblue.400');
      }
    }
  }
  ::v-deep ul.nav-bottombar-list .nav-bottombar-list-item {
    @apply flex justify-center items-center;
    &:nth-child(3) {
      a {
        @apply bg-blue-400 w-11 h-11 flex justify-center items-center p-2 rounded-full mx-auto;
        svg {
          @apply mt-1;
          path {
            fill: white;
          }
        }
      }
    }
  }
}
</style>
