<template>
  <div class="overlay">
    <AppModal v-model="model" title="">
      <template #header>
        <AppModalHeader>
          <template #default>
            <img class="trashCan" src="@galileo/assets/images/handwave.png" alt="Hand wave icon" />
          </template>
        </AppModalHeader>
      </template>

      <div class="modal-body">
        <slot> </slot>
      </div>
      <template #footer>
        <AppModalFooter class="footer">
          <AppButton theme="primary" @click="$emit('close')"> {{ buttonTitle }} </AppButton>
          <div class="appLink">
            <AppLink @click="learnMore" class="classic-switch-text" target="_new">
              {{ $t('ComponentEditRecipientDetailsModal.LearnMore').value }}
            </AppLink>
          </div>
        </AppModalFooter>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'


import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'

import { IconWarningRed } from '@oen.web.vue2/icons'

import { useI18nStore, useAppStore } from '@galileo/stores'


import {
  useVModel,
  AppModal,
  AppModalFooter,
  AppButton,
  AppModalHeader,
  AppIcon,
  AppLink,
} from '@oen.web.vue2/ui'

import HelpDesk, { HelpDeskProperties } from '@galileo/constants/helpdesk.const'

export default defineComponent({
  components: {
    AppModal,
    AppModalFooter,
    AppButton,
    AppModalHeader,
    AppIcon,
    IconWarningRed,
    XeBackButton,
    AppLink,
  },
  emits: ['close'],
  props: {
    title: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: 'Continue',
    },
    value: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const appStore = useAppStore()
    const { model } = useVModel(props, emit)
    const { $t } = useI18nStore()

    const learnMore = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
        constName: HelpDeskProperties.INTRODUCING_THE_NEW_XE_WEB_EXPERIENCE,
        article: HelpDesk[HelpDeskProperties.INTRODUCING_THE_NEW_XE_WEB_EXPERIENCE],
      })
    }

    const confirm = () => {
      emit('confirm')
    }

    return { model, confirm, $t, learnMore }
  },
})
</script>

<style scoped>
.title {
  @apply font-semibold text-2xl leading-8;
}
::v-deep .modal-body > h1 {
  @apply font-semibold text-2xl leading-8;
}
.modal-body {
  @apply text-center;
}
::v-deep .modal-body > p {
  @apply mt-8 text-secondary-text;
}

::v-deep .card-footer > .button {
  @apply w-full !important;
}

.footer {
  display: table-column;
}
.appLink {
  @apply pt-8;
  @apply pb-2;
  width: 100%;
  text-align: center;
}
.overlay {
  z-index: 51;
}
</style>
