<template>
  <div v-if="showBanner">
    <div class="home-page-notificaton">
      <div class="banner-text">
        <IconWarningYellow v-if="icon === 'Warning'" class="banner-icon" />
        <IconSimpleClock v-if="icon === 'Clock'" class="banner-icon" />
        <IconConfetti v-if="icon === 'Confetti'" class="banner-icon" />
        <p v-if="showDocumentsRequiredBanner">
          {{ $t('ComponentAccountTopBanner.ActionRequired').value }}
          <strong class="underline-text" @click="goToUploadDocuments">{{
            $t('ComponentAccountTopBanner.DocumentUploadRequired').value
          }}</strong>
        </p>
        <p v-else v-html="bannerText"></p>
      </div>
      <AppBackButton
        v-if="!showDocumentsRequiredBanner"
        class="home-page-notificaton-back-button"
        @click="closeBanner"
      />
    </div>
  </div>
</template>

<script>
import { useRouter } from '@galileo/composables/useRouter'

import { onBeforeMount, computed, ref, watch } from '@vue/composition-api'

import { AppBackButton } from '@oen.web.vue2/ui'
import { IconWarningYellow } from '@oen.web.vue2/icons'
import { IconConfetti, IconSimpleClock } from '@galileo/assets/icons/vue'
import { storeToRefs } from 'pinia'

import { ACCOUNT_KYC_STATUS } from '@galileo/constants/accountKycStatus.const.ts'

import { useStpStore, useI18nStore, useProfileStore, useAuthStore } from '@galileo/stores'

export default {
  components: {
    AppBackButton,
    IconWarningYellow,
    IconConfetti,
    IconSimpleClock,
  },
  setup() {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const profileStore = useProfileStore()
    const user = authStore.user
    const stpStore = useStpStore()
    const router = useRouter()

    const accountRestricted = user.customer.accountRestricted
    const accountNotApproved = user.customer.accountKycStatus === ACCOUNT_KYC_STATUS.NOT_APPROVED

    const showDocumentsRequiredBanner = computed(() => {
      const hasDocuments = stpStore.hasDocumentsToUpload
      if (hasDocuments) {
        icon.value = 'Warning'
      }
      return hasDocuments
    })

    const showBanner = ref(false)
    const { hasNoTransactions, shouldCheckStatus } = storeToRefs(profileStore)

    const goToUploadDocuments = () => {
      router.replace('/upload-documents')
    }

    const icon = ref('')
    const bannerText = ref('')

    onBeforeMount(() => {
      if (shouldCheckStatus.value) {
        if (accountRestricted) {
          icon.value = 'Warning'
          bannerText.value = $t('ComponentAccountTopBanner.AccountRestrictedMessage').value
          showBanner.value = true
        } else if (accountNotApproved) {
          showBanner.value = true
          icon.value = 'Clock'
          bannerText.value = $t('ComponentAccountTopBanner.AccountNotApprovedMessage').value
        } else if (hasNoTransactions.value) {
          // if approved and not restricted account is without transactions we are displaying banner 'Congratulations! You’ve been approved to start sending.'
          icon.value = 'Confetti'
          bannerText.value = $t('ComponentAccountTopBanner.AccountApprovedMessage').value
          showBanner.value = true
        }
      }
    })

    const closeBanner = () => {
      profileStore.shouldCheckStatus = false
      showBanner.value = false
    }

    return {
      $t,
      closeBanner,
      goToUploadDocuments,
      showBanner,
      bannerText,
      icon,
      showDocumentsRequiredBanner,
    }
  },
}
</script>

<style scoped>
.home-page-notificaton {
  @apply w-full flex items-center text-center;
  background-color: rgba(255, 255, 255, 1);
  min-height: 3rem;
}
.home-page-notificaton-back-button {
  @apply w-auto;
}
.banner-text {
  @apply text-secondary-text;
  @apply flex flex-1 justify-center items-center gap-1;
  ::v-deep .underline-text {
    @apply underline;
    cursor: pointer;
  }
}
.banner-icon {
  @apply w-4 h-4 inline-block;
}
@media only screen and (max-width: 820px) {
  .home-page-notificaton {
    align-items: flex-start;
  }
  .home-page-notificaton-back-button {
    height: 3.6rem;
  }
  .banner-text {
    @apply flex flex-1 justify-center items-start gap-2 text-left p-4;
  }
  .banner-icon {
    @apply h-5 inline-block;
    min-width: 1.25rem;
  }
}
</style>
