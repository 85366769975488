






































import { computed, defineComponent } from '@vue/composition-api'
import {
  AppAlert,
  AppIcon,
  useMediaQuery,
} from '@oen.web.vue2/ui'
import IconWarningAltFilled from '@oen.web.vue2/icons/src/components/carbon/IconWarningAltFilled.vue'

import {
  useI18nStore,
  useKYCRefreshCorporateStore,
} from '@galileo/stores'

export default defineComponent({
  name: 'KYCRefreshCorporateAlert',
  components: {
    AppAlert,
    AppIcon,
    IconWarningAltFilled,
  },
  setup() {
    const { $t } = useI18nStore()
    const kycRefreshCorporateStore = useKYCRefreshCorporateStore()

    const isGracePeriod = computed(() => kycRefreshCorporateStore.isGracePeriod)
    const daysLeft = computed(() => kycRefreshCorporateStore.daysLeft)
    const fewDaysLeft= computed(() => kycRefreshCorporateStore.daysLeft <= kycRefreshCorporateStore.DAYS_LEFT_WARNING)
    const showAlert = computed(() => isGracePeriod.value || kycRefreshCorporateStore.isOverdue)
    const mq = useMediaQuery()
    const showIcon = computed(() => mq.current !== 'xs')

    return {
      $t,
      showAlert,
      isGracePeriod,
      daysLeft,
      fewDaysLeft,
      showIcon,
      startKycRefresh: kycRefreshCorporateStore.startKycRefresh,
      verifyButtonClicked: kycRefreshCorporateStore.verifyButtonClicked,
      isRolledOut: kycRefreshCorporateStore.isRolledOut
    }
  },
})
