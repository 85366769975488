<template>
  <div class="dashboard-layout">
    <WelcomeXeModal
      v-if="showWelcomeMessage"
      :value="true"
      :button-title="$t('PageActivity.ButtonContinue').value"
      @close="dismissWelcomeMessage"
      @confirm="dismissWelcomeMessage"
    >
      <h1 class="title">{{ $t('WelcomeModal.WelcomeTitle').value }}</h1>
      <p>
        {{ $t('WelcomeModal.WelcomeMessage').value }}
      </p>
    </WelcomeXeModal>

    <header class="dashboard-header">
      <XeLogo class="dashboard-header-image" />
      <div
        class="dashboard-header-title"
        v-if="$route.name !== 'BalancesAndPayments' && $route.name !== 'AccountPaymentMethods'"
      >
        {{ $route.name.replace(/([A-Z])/g, ' $1').trim() }}
      </div>
      <div class="dashboard-header-title" v-if="$route.name === 'BalancesAndPayments'">
        {{ routeTitle }}
      </div>
      <div class="dashboard-header-title" v-else-if="$route.name === 'AccountPaymentMethods'">
        {{ $t('PageDashboard.PaymentMethod').value }}
      </div>
      <ProfileSelectionDropdown class="dashboard-header-profile" />
    </header>
    <div class="dashboard-container">
      <AccountTopBanner v-if="user && isCorporate && transactionsChecked" />
      <KYCRefreshCorporateAlert />

      <Transition name="fade">
        <div
          v-if="homePageMessage"
          class="home-page-notificaton"
          :class="{ active: homePageMessage }"
        >
          <p
            v-if="homePageMessage === homePageMessageType.DocumentRequired"
            class="text-secondary-text"
          >
            <IconWarningYellow class="message-icon" />
            <b>{{ $t('PageDashboard.DocumentsAreRequiredBold').value }}</b>
            {{ $t('PageDashboard.DocumentsAreRequiredText').value }}
            <AppLink @click="routeToUploadDocument">{{
              $t('PageDashboard.DocumentsAreRequiredLink').value
            }}</AppLink>
          </p>
          <p
            v-else-if="homePageMessage === homePageMessageType.DocumentInReview"
            class="text-secondary-text"
          >
            <IconMemo class="message-icon" />
            <b>{{ $t('PageDashboard.DocumentsInReviewBold').value }}</b>
            {{ $t('PageDashboard.DocumentsInReviewText').value }}
          </p>
          <p v-else class="home-page-message">
            <IconWarningYellow class="message-icon" />
            <span class="home-page-message-text" v-html="homePageMessage"></span>
          </p>
          <AppBackButton class="home-page-notificaton-back-button" @click="dismissMessage" />
        </div>
      </Transition>
      <div class="dashboard">
        <XeSidebar v-if="mq.md === true" />
        <main :class="{ 'remove-padding': dashboard.paddingIsRemoved }">
          <div v-if="mq.sm === true" class="profile-selection">
            <ProfileSelectionDropdown />
          </div>
          <ModalRouterView />
        </main>
      </div>
    </div>
    <XeBottombar v-if="mq.md === false" class="fixed w-full bottom-0" />
  </div>
</template>

<script>
import { useRouter } from '@galileo/composables/useRouter'

import { storeToRefs } from 'pinia'
import { reactive, provide, onBeforeMount, computed, ref } from '@vue/composition-api'
import XeBottombar from '@galileo/components/XeBottombar/XeBottombar'
import XeSidebar from '@galileo/components/XeSidebar/XeSidebar'
import ProfileSelectionDropdown from '@galileo/components/Views/Account/Profile/ProfileSelectionDropdown'
import KYCRefreshCorporateAlert from '@galileo/components/KYCRefreshCorporate/KYCRefreshCorporateAlert'
import ModalRouterView from '@galileo/views/ModalRouterView'
import XeLogo from '@galileo/components/XeLogo/XeLogo'
import WelcomeXeModal from '@galileo/components/WelcomeXeModal/WelcomeXeModal'
import { IconMemo, IconWarningYellow } from '@oen.web.vue2/icons'
import { HomePageMessageType } from '@galileo/models/HomePageMessageType'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { useMediaQuery, AppBackButton, AppLink } from '@oen.web.vue2/ui'

import AccountTopBanner from '@galileo/components/Views/Account/AccountTopBanner.vue'

import { ACCOUNT_KYC_STATUS } from '@galileo/constants/accountKycStatus.const.ts'

import {
  useAnalyticsStore,
  useEnvStore,
  useI18nStore,
  usePageStore,
  useDeviceStore,
  useActivityStore,
  useAuthStore,
  useProfileStore,
  useStpStore,
  useAppStore,
} from '@galileo/stores'

export default {
  name: 'Dashboard',
  components: {
    XeBottombar,
    XeSidebar,
    ProfileSelectionDropdown,
    ModalRouterView,
    XeLogo,
    WelcomeXeModal,
    AppBackButton,
    AppLink,
    IconMemo,
    IconWarningYellow,
    AccountTopBanner,
    KYCRefreshCorporateAlert,
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const pageStore = usePageStore()
    const stpStore = useStpStore()
    const activityStore = useActivityStore()
    const analyticsStore = useAnalyticsStore()
    const profileStore = useProfileStore()
    const deviceStore = useDeviceStore()

    const { user } = storeToRefs(authStore)
    const { homePageMessage } = storeToRefs(profileStore)

    const showWelcomeMessage = computed(() => deviceStore.getWelcomeMessage())

    const routeToUploadDocument = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.UPLOAD_DOCUMENTS_ACCESSED,
      })
      router.push({ name: 'DocumentUpload' })
    }

    const dismissWelcomeMessage = () => {
      deviceStore.setWelcomeMessage(false)
      emit('close')
    }

    let routeTitle = 'Payments & Balances'
    const dismissMessage = () => {
      let profileId = user.value?.customer?.id

      let homePageMessageData = pageStore.getHomePageMessageData(profileId)
      if (homePageMessageData) {
        homePageMessageData.dismissed = !homePageMessageData.dismissed
        pageStore.setHomePageMessageData({ profileId, homePageMessageData })
      }
      profileStore.homePageMessage = null
    }

    onBeforeMount(async () => {
      await profileStore.loadHomePageMessage()
      const isCorporate = authStore.isCorporateAccount

      const accountNotApproved =
        user.value.customer.accountKycStatus === ACCOUNT_KYC_STATUS.NOT_APPROVED

      if (isCorporate && accountNotApproved && !useEnvStore().isProduction) {
        await stpStore.getRequiredDocumentsList()
      }

      const { currentRoute } = useAppStore()

      if (currentRoute?.path === '/') {
        router.push({
          name: 'Activity',
        })
      }
    })

    const dashboard = reactive({
      paddingIsRemoved: false,
    })

    provide('dashboardView', dashboard)

    const homePageMessageType = HomePageMessageType

    const transactionsChecked = computed(() => {
      return activityStore.getTransactionsChecked
    })

    return {
      mq: useMediaQuery(),
      dashboard,
      dismissMessage,
      $t,
      showWelcomeMessage,
      homePageMessage,
      dismissWelcomeMessage,
      homePageMessageType,
      routeToUploadDocument,
      routeTitle,
      user,
      transactionsChecked,
      isCorporate: authStore.isCorporateAccount,
    }
  },
}
</script>
<style scoped>
::v-deep .nav-sidebar {
  @if $homePageMessage.dismissed == false {
    margin-top: 20px;
  } @else {
    margin-top: 0px;
  }
}
.home-page-notificaton-back-button {
  @apply w-auto;
}
.home-page-notificaton {
  @apply w-full flex items-start text-center;
  background-color: #e9ebf0;
}
.home-page-notificaton p {
  @apply flex-1;
}
.dashboard-container {
  display: flex;
  flex-direction: column;
  @apply bg-white w-full h-full py-14;
}
.dashboard {
  @apply w-full h-full;
  @apply flex flex-col;
  overflow: hidden;

  main {
    @apply flex-1 w-full overflow-y-auto pt-0;

    &.remove-padding {
      @apply p-0;
    }

    > * {
      @apply w-full;
    }
  }
  @screen sm {
    @apply flex-row pt-0;
    main {
      @apply h-full w-auto flex-grow pt-0;
    }
  }
}
.dashboard-layout {
  @apply w-full;
  @apply h-full;
  @screen sm {
    @apply h-full;
  }
}
.dashboard-header {
  @apply h-14 p-2 fixed w-full top-0 flex flex-row flex-grow-0;
  @apply items-center justify-between bg-white z-10 shadow-ria-1;
  .dashboard-header-image {
    @apply w-12 h-8 items-center;
  }
  .dashboard-header-title {
    @apply type-h3 ml-8;
    font-weight: var(--theme-dashboard-header-title-weight, 500);
  }
  ::v-deep .profile-selector {
    @apply relative;
  }
}
@screen md {
  .dashboard-container {
    @apply py-0 !important;
  }
}
@screen sm {
  .dashboard-header {
    @apply hidden;
  }
  .dashboard-container {
    @apply pt-0 pb-14 bg-gray-bg;
  }
}
.profile-selection {
  @apply h-auto !important;
  @apply pt-4 pr-4 z-100;
  padding-bottom: 28px;
  display: flex;
  justify-content: flex-end;

  ::v-deep .profile-selector {
    @apply p-0;
  }
}
.profile-selection ~ .layout-dashboard-page,
.profile-selection ~ .layout-dashboard-card {
  @apply pt-0;
}
.profile-selection + div {
  @apply pt-0;
  ::v-deep .layout-dashboard-page {
    @apply pt-0;
  }
}
main > .card {
  border: 0px;
}
.message-icon {
  display: inline-block;
  width: 20px;
  margin-right: 4px;
}
.home-page-message {
  @apply flex flex-row pl-4 pt-4 pb-4;
  @apply text-secondary-text;
  font-size: 16px;
  text-align: left;
  justify-content: flex-start;
}
.home-page-message > .message-icon {
  height: 20px;
  min-width: 20px;
}
::v-deep .home-page-message-text > a {
  @apply text-blue-text;
}
::v-deep .home-page-message-text > a::after {
  content: '\a';
  white-space: pre;
}
::v-deep .home-page-message-text > a:hover {
  @apply text-blue-text;
  @apply underline;
  filter: drop-shadow(0px 0px 0px rgba(0, 108, 224, 1));
}
::v-deep .home-page-message-text > h1 {
  @apply font-semibold text-lg mx-1;
}
::v-deep .home-page-message-text > h2 {
  @apply font-semibold text-base mx-1;
}
::v-deep .home-page-message-text > button {
  @apply mx-1;
}
</style>
